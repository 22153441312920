import React from 'react';
import { rhythm } from '../utils/typography';

class Bio extends React.Component {
  componentDidMount() {
    document.body.classList.add('light');
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2),
        }}
      >
        <img
          src="https://avatars2.githubusercontent.com/u/14992757?s=500&u=c1a04d137ac96499bb1b908870c6885fe3612996&v=5"
          alt="Alireza valizade"
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
            borderRadius: '50%',
          }}
        />
        <p style={{ maxWidth: 310 }}>
          Personal blog by{' '}
          <a href="https://twitter.com/alirezavlizade">Alireza valizade</a>.{' '}
          I write to remember
        </p>
      </div>
    );
  }
}

export default Bio;
